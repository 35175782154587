import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MemeTweet from "../components/MemeTweet";
import Cookies from "js-cookie";

function Approve() {
  const [unapproved, setUnapproved] = useState([]);

  const fetchUnapproved = async () => {
    const url = `${process.env.REACT_APP_MEME_API_URL}/unapproved`;
    const resp = await fetch(url);
    const respJson = await resp.json();
    console.log(`unapproved`, respJson);
    setUnapproved(respJson);
  };

  const reloadMeme = async (memeId) => {
    console.log(`approving ${memeId}`);
    const url = `${process.env.REACT_APP_MEME_API_URL}/reload/${memeId}`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    };
    const resp = await fetch(url, options);
    await fetchUnapproved();
  };

  const approveMeme = async (memeId) => {
    console.log(`approving ${memeId}`);
    const url = `${process.env.REACT_APP_MEME_API_URL}/approve/${memeId}`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    };
    const resp = await fetch(url, options);
    await fetchUnapproved();
  };

  const declineMeme = async (memeId) => {
    console.log(`declining ${memeId}`);
    const url = `${process.env.REACT_APP_MEME_API_URL}/decline/${memeId}`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    };
    const resp = await fetch(url, options);
    await fetchUnapproved();
  };

  useEffect(() => {
    fetchUnapproved();
  }, []);

  return (
    <div>
      <Container align="center">
        <h3>Approval Portal</h3>
        {unapproved.length === 0 && (
          <Box>
            <h3>No results found.</h3>
          </Box>
        )}
        {unapproved.map((item) => {
          if (item.id) {
            return (
              <Box
                key={item.tweetId}
                sx={{
                  width: 320,
                  // backgroundColor: "grey.800",
                  paddingTop: 2,
                  paddingBottom: 2,
                  marginBottom: 2,
                }}
              >
                <button
                  sx={{ marginRight: 2 }}
                  className="defaultButton"
                  onClick={() => approveMeme(item.id)}
                >
                  Approve
                </button>
                <button
                  variant="contained"
                  className="defaultButton"
                  onClick={() => declineMeme(item.id)}
                >
                  Decline
                </button>
                <button
                  variant="contained"
                  className="defaultButton"
                  onClick={() => reloadMeme(item.id)}
                >
                  Reload
                </button>
                <Box>
                  <img src={item.tweetImgUrl} width={'100%'} />
                  {/* <MemeTweet tweetId={item.tweetId} /> */}
                </Box>
              </Box>
            );
          }
        })}
      </Container>
    </div>
  );
}

export default Approve;
