import styles from './MemeBanner.module.css';

function MemeBanner(  ) {

  

  return (
    <div className={styles.bannerContainer}>
     <img className={styles.image} src="/img/layout/d1outline.png" alt="dicky" /><span className={styles.text}>Community MEMES</span> 
     <img className={styles.image} src="/img/layout/d2outline.png" alt="dicky" /><span className={styles.text}>Community MEMES</span>
     <img className={styles.image} src="/img/layout/d3outline.png" alt="dicky" /><span className={styles.text}>Community MEMES</span>
     <img className={styles.image} src="/img/layout/d4outline.png" alt="dicky" /><span className={styles.text}>Community MEMES</span>
     <img className={styles.image} src="/img/layout/d5outline.png" alt="dicky" /><span className={styles.text}>Community MEMES</span>
     <img className={styles.image} src="/img/layout/d7outline.png" alt="dicky" /><span className={styles.text}>Community MEMES</span>
     <img className={styles.image} src="/img/layout/d1outline.png" alt="dicky" /><span className={styles.text}>Community MEMES</span> 
     <img className={styles.image} src="/img/layout/d2outline.png" alt="dicky" /><span className={styles.text}>Community MEMES</span>
     <img className={styles.image} src="/img/layout/d3outline.png" alt="dicky" /><span className={styles.text}>Community MEMES</span>
     <img className={styles.image} src="/img/layout/d4outline.png" alt="dicky" /><span className={styles.text}>Community MEMES</span>
     <img className={styles.image} src="/img/layout/d5outline.png" alt="dicky" /><span className={styles.text}>Community MEMES</span>
     <img className={styles.image} src="/img/layout/d7outline.png" alt="dicky" /><span className={styles.text}>Community MEMES</span>
    </div>
  );
}

export default MemeBanner;