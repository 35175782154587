import { u64 } from "@saberhq/token-utils";
// import type { PublicKey } from "@solana/web3.js";
// import type BN from "bn.js";
import { keccak_256 } from "js-sha3";

import { MerkleTree } from "./merkle-tree";

export class BalanceTree {
  _tree;

  constructor(balances) {
    this._tree = new MerkleTree(
      balances.map(({ account, amount }, index) => {
        return BalanceTree.toNode(index, account, amount);
      })
    );
  }

  static verifyProof(
    index,
    account,
    amount,
    proof,
    root
  ) {
    let pair = BalanceTree.toNode(index, account, amount);
    for (const item of proof) {
      pair = MerkleTree.combinedHash(pair, item);
    }

    return pair.equals(root);
  }

  // keccak256(abi.encode(index, account, amount))
  static toNode(index, account, amount) {
    const buf = Buffer.concat([
      new u64(index).toArrayLike(Buffer, "le", 8),
      account.toBuffer(),
      new u64(amount).toArrayLike(Buffer, "le", 8),
    ]);
    return Buffer.from(keccak_256(buf), "hex");
  }

  getHexRoot() {
    return this._tree.getHexRoot();
  }

  // returns the hex bytes32 values of the proof
  getHexProof(index, account, amount) {
    return this._tree.getHexProof(BalanceTree.toNode(index, account, amount));
  }

  getRoot() {
    return this._tree.getRoot();
  }

  getProof(index, account, amount) {
    return this._tree.getProof(BalanceTree.toNode(index, account, amount));
  }
}
