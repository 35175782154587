import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Masonry from "@mui/lab/Masonry";
import MemeImage from "../components/MemeImage";
import { useAuth } from "../hooks/useAuth";
import useSWR from "swr";
import { fetcher } from "../utils/fetchers.js";
import styles from "./Feed.module.css";
import Cookies from "js-cookie";
import MemeBanner from "../components/MemeBanner.jsx";
import InfiniteScroll from 'react-infinite-scroller';

const MEMES_PER_PAGE = 200;

function Feed() {
  const [errorMsg, setErrorMsg] = useState(null);
  const [success, setSuccess] = useState(null);
  const [allMemes, setAllMemes] = useState([]);
  const [hasFetchedAll, setHasFetchedAll] = useState(false);
  const [memeIds, setMemeIds] = useState(new Set());
  const { authenticated } = useAuth();
  const [memeUrl, setMemeUrl] = useState("");
  const [page, setPage] = useState(1);

  const { data, error, isValidating } = useSWR(
    `${process.env.REACT_APP_MEME_API_URL}/latest?page=${page}&limit=${MEMES_PER_PAGE}`,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  );

  useEffect(() => {
    if (data) {
      const uniqueMemes = data.filter((meme) => !memeIds.has(meme.id));
      if (uniqueMemes.length > 0) {
        setAllMemes((prevMemes) => [...prevMemes, ...uniqueMemes]);
        setMemeIds((prevIds) => new Set([...prevIds, ...uniqueMemes.map((meme) => meme.id)]));
      }
      if (data.length < MEMES_PER_PAGE) {
        setHasFetchedAll(true);
      }
    }
  }, [data]);

  const memeUrlChange = (event) => {
    setMemeUrl(event.target.value);
  };

  const submitMeme = async () => {
    setErrorMsg(null);
    setSuccess(null);
    if (memeUrl === '' || !memeUrl) {
      return setErrorMsg("Add your link!")
    }
    if (!authenticated) {
      setErrorMsg("Please connect to Twitter!");
    } else {
      const url = `${process.env.REACT_APP_MEME_API_URL}/meme`;
      const options = {
        method: "POST",
        body: JSON.stringify({ url: memeUrl }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      };
      const resp = await fetch(url, options);
      const jsonResp = await resp.json();
      if (jsonResp.error) {
        setErrorMsg(jsonResp.error);
      } else {
        setSuccess(true);
      }
    }
  };

  const fetchMoreData = () => {
    if (!isValidating && !hasFetchedAll) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <>
      <Container align="center" maxWidth="xl">
        <div>
          <div className={styles.submitContainer}>
            <div className={styles.left}>
              <h2 className={styles.stick}>Stick your Dicky in the twitter link field</h2>
              <img className={styles.arrowRight} src="/img/layout/arrowright.png" alt="arrow right" />
            </div>
            <img className={styles.dicky} src="/img/layout/dicky-with-shadow.png" alt="dicky with shadow" />
            <div className={styles.center}>
              <h1>Dicky Feed</h1>
              <div className={styles.inputContainer}>
                <input value={memeUrl} onChange={memeUrlChange} className={styles.input} disabled={true} placeholder="Paste your meme Twitter Link" />
                <button onClick={submitMeme} className={styles.submitButton} disabled={true}>
                  <img src="/img/memeAssets/d5.png" alt="dicky" />
                </button>
              </div>
              <span className={styles.closed}>Meme portal is closed!</span>

              {errorMsg && <span className={styles.error}>{errorMsg}<button onClick={() => setErrorMsg(null)}>X</button></span>}
              {success && <span className={styles.success}>Meme Submitted!</span>}
            </div>
            <div className={styles.right}>
              <h2 className={styles.choke}>Choke Dicky</h2>
              <img className={styles.arrowLeft} src="/img/layout/arrowleft.png" alt="arrow left" />
            </div>
          </div>
        </div>
      </Container>
      <Container disableGutters maxWidth={false}>
        <MemeBanner />
      </Container>
      <Container align="center" maxWidth="xl">
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMoreData}
          hasMore={!hasFetchedAll}
          loader={<h4 key="feed-loader">Loading...</h4>}
        >
          <Masonry columns={{ xs: 1, sm: 2, md: 2, lg: 3 }} spacing={3}>
            {allMemes.map((meme) => (
              <MemeImage
                key={meme.id}
                memeImg={meme.tweetImgUrl}
                memeUrl={meme.url}
              />
            ))}
          </Masonry>
        </InfiniteScroll>
        {error && <p>Error: {error.message}</p>}
      </Container>
    </>
  );
}

export default Feed;
