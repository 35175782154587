import * as anchor from "@project-serum/anchor";
import { utils, Program } from "@project-serum/anchor";
import { clusterApiUrl, Connection, PublicKey, SystemProgram, ComputeBudgetProgram } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@saberhq/token-utils";
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token } from "@solana/spl-token";
import invariant from "tiny-invariant";
import idl from "../claim/idl/merkle_distributor.json";
// import { MerkleDistributor } from "./idl/merkle_distributor";
import * as base58 from "bs58";

export const commitmentLevel = "processed";
export const endpoint =
  process.env.NEXT_PUBLIC_CLAIM_RPC_URL || clusterApiUrl("devnet");

export const connection = new Connection(endpoint, { commitment: commitmentLevel, confirmTransactionInitialTimeout: 60000 });
// export const PROGRAM_ID = new PublicKey('BMas2pUrC5GR1ZJFbJLy2UmBcEgCfdxB5QLSBrLRnvK4');
export const PROGRAM_ID = new PublicKey('MRKGLMizK9XSTaD1d1jbVkdHZbQVCSnPpYiTw9aKQv8');

export const merkleProgramInterface = JSON.parse(JSON.stringify(idl));

export const BASE_PUBKEY = new PublicKey('7Grs4umps3QmjqyQFrJVAzVzhFYNJGjVuF7aueWZFkXe');

// export const BASE_PUBKEY = new PublicKey('mErkCbK7Dg29yxKVv3K4iTXNniVTqCGkqeZhzAWyEV1');
const MINT = new PublicKey('E3HZfBaZiaezRr442SZZD7nzQzqX1F2HTyAWbBnVv7tY'); // devnet
// const MINT = new PublicKey('DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263'); // BONK

// export const MINT_DECIMAL_MULTIPLIER = 100000; // 5 decimals

export const MINT_DECIMAL_MULTIPLIER = 1000000000; // 9 decimals
export const PRIORITY_FEE_MICRO_LAMPORTS = 20_000;

export const getClaimInstruction = async(
    walletKey,
    claimAmount,
    index,
    proof,
) => {
    const program = await loadProgram(walletKey);

    const [distributor, distBump] = await findDistributorKey(BASE_PUBKEY);
    // const distributor = new PublicKey('5y3tQyEVKvuXJYHD8G9UqKatsHL54mvJK6oUMViSTD2g');
    const [distributorATA, daBump] = await getATAAddressSync({ mint: MINT, owner: distributor });
    const [claimantATA, clataBump] = await getATAAddressSync({ mint: MINT, owner: walletKey });
    const [claimStatus, bump] = await findClaimStatusKey(new anchor.BN(index), distributor);
    console.log(`distributor`, distributor.toString());
    console.log(walletKey.toString());

    const ix = await program.instruction.claim(
        bump,
        new anchor.BN(index),
        new anchor.BN(claimAmount),
        proof,
        {
            accounts: {
                distributor,
                claimStatus,
                from: distributorATA,
                to: claimantATA,
                claimant: walletKey,
                payer: walletKey,
                systemProgram: SystemProgram.programId,
                tokenProgram: TOKEN_PROGRAM_ID,
            },
        }
    );
    
    return ix;
}

export const getPriorityFeeInstruction = () => {
	  return ComputeBudgetProgram.setComputeUnitPrice({ microLamports: PRIORITY_FEE_MICRO_LAMPORTS });
}
export const getATAInstruction = async (
  walletKey,
) => {
  // see if claimant has ATA
  // const [claimantATA, clataBump] = await getATAAddressSync({ mint: MINT, owner: wallet.publicKey });
  console.log(`args`, ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  MINT,
  walletKey,
  false)

  const userTokenAccountAddress = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    MINT,
    walletKey,
    false,
  );
  let account;
  try {
    account = await connection.getAccountInfo(userTokenAccountAddress, "processed");
    console.log(`account info`, account);
  } catch (error) {
    console.log(`ata error`, error);
  }

  if (!account) {
    const ix = Token.createAssociatedTokenAccountInstruction(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      MINT,
      userTokenAccountAddress,
      walletKey,
      walletKey,
    );
    return ix;
  }
}

export const loadProgram = async(
    wallet,
) => {
    const provider = new anchor.AnchorProvider(connection, wallet, {
        preflightCommitment: commitmentLevel,
      });
    return new Program(
      merkleProgramInterface,
      PROGRAM_ID,
      provider
    );
}

export const findDistributorKey = async (
    base
  ) => {
    return await PublicKey.findProgramAddress(
      [utils.bytes.utf8.encode("MerkleDistributor"), base.toBytes()],
      PROGRAM_ID
    );
  };

  export const getATAAddressSync = async({
    mint,
    owner,
  }) => {
    return await PublicKey.findProgramAddress(
      [owner.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
  
      ASSOCIATED_TOKEN_PROGRAM_ID
    );
  };

  export const findClaimStatusKey = async (
    index,
    distributor
  ) => {
    return await PublicKey.findProgramAddress(
      [
        utils.bytes.utf8.encode("ClaimStatus"),
        index.toArrayLike(Buffer, "le", 8),
        distributor.toBytes(),
      ],
      PROGRAM_ID
    );
  };

  export const toBytes32Array = (b) => {
    invariant(b.length <= 32, `invalid length ${b.length}`);
    const buf = Buffer.alloc(32);
    b.copy(buf, 32 - b.length);
  
    return Array.from(buf);
  };

  export const getClaimTransaction = async (walletKey, claimData) => {
    if (walletKey && claimData && claimData.claim) {
        const txn = new anchor.web3.Transaction();

        const feeIx = getPriorityFeeInstruction();

        if (feeIx) {
          txn.add(feeIx);
        }

        const ataIx = await getATAInstruction(walletKey);

        if (ataIx) {
          txn.add(ataIx);
        }
        
        const ix = await getClaimInstruction(
          walletKey,
          claimData.claimant.earnings,
          claimData.claim.index,
          claimData.proof,
        );
        txn.add(ix);

        let { blockhash } = await connection.getLatestBlockhash();
        txn.recentBlockhash = blockhash;
        txn.feePayer = walletKey;

        return txn;
      }
    }


export const sendTxn = async (txn) => {
  const TX_RETRY_INTERVAL = 2000;

  const rpcUrl = process.env.NEXT_PUBLIC_CLAIM_RPC_URL;
  console.log(rpcUrl);
  const connection = new Connection(rpcUrl || clusterApiUrl('devnet'));
  let blockhashResult = await connection.getLatestBlockhash({
    commitment: "confirmed",
  });

  let txSignature = null;
  let confirmTransactionPromise = null;
  let confirmedTx = null;

  const signatureRaw = txn.signatures[0];
  txSignature = base58.encode(signatureRaw);

  let txSendAttempts = 1;
  try {
    // confirmTransaction throws error, handle it
    confirmTransactionPromise = connection.confirmTransaction(
      {
        signature: txSignature,
        blockhash: blockhashResult.blockhash,
        lastValidBlockHeight: blockhashResult.lastValidBlockHeight,
      },
      "confirmed"
    );

    await connection.sendTransaction(
      txn,
      {
        skipPreflight: true,
        maxRetries: 0,
      }
    );
    console.log(`signature: ${txSignature}`);

    confirmedTx = null;
    while (!confirmedTx) {
      confirmedTx = await Promise.race([
        confirmTransactionPromise,
        new Promise((resolve) =>
          setTimeout(() => {
            resolve(null);
          }, TX_RETRY_INTERVAL)
        ),
      ]);
      if (confirmedTx) {
        break;
      }

      await connection.sendTransaction(
        txn,
        {
          skipPreflight: true,
          maxRetries: 0,
        }
      );

      console.log(`${new Date().toISOString()} Tx not confirmed after ${TX_RETRY_INTERVAL * txSendAttempts++}ms, resending`);

    }
  } catch (error) {
    console.error(error);
  }

  if (!confirmedTx) {
    console.log(`${new Date().toISOString()} Transaction failed`);
    throw new Error("Transaction failed");
  }

  console.log(`${new Date().toISOString()} Transaction successful`);

  return { signature: txSignature, confirmed: confirmedTx };
}