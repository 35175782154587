import React, { useState, useMemo, useEffect } from 'react';

import { Route, Routes } from "react-router-dom";
import Feed from './pages/Feed';
import Leaderboard from './pages/Leaderboard';
import Assets from './pages/Assets';
import Claim from './pages/Claim';
import Profile from './pages/Profile';
import Approve from './pages/Approve';
import ProtectedRoute from './components/Layout/ProtectedRoute';
import Header from './components/Layout/Header';
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider, WalletDisconnectButton, WalletMultiButton, WalletConnectButton } from "@solana/wallet-adapter-react-ui";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from '@solana/web3.js';
import { AuthContextProvider } from './hooks/useAuth';
import useAuth from './hooks/useAuth';
import backgroundImage from "./images/bg.png";

import './assets/styles/wallet-adapter-styles.css';
import './App.css';
import { ClaimDataProvider } from "./providers/ClaimProvider";


function Web3Provider(props) {
  let network = "https://indulgent-thrilling-feather.solana-devnet.discover.quiknode.pro/9344a967a6e2a9db8c32232e92bd95851dd4c6d3/";
  
  if (process.env.REACT_APP_SOLANA_NETWORK) {
    network = clusterApiUrl(process.env.REACT_APP_SOLANA_NETWORK);
  }

  const wallets = useMemo(
      () => network && [
        new PhantomWalletAdapter(),
      ],
      [network]
  );


  return (
      <ConnectionProvider endpoint={network}>
          <WalletProvider wallets={wallets} autoConnect={true}>
            <WalletModalProvider>
            {/* <WalletDisconnectButton /> */}
            {/* <WalletMultiButton /> */}
            {props.children}
            </WalletModalProvider>
          </WalletProvider>
      </ConnectionProvider>
    );
}

function AppChild() {
  const { authenticated, user, canApprove } = useAuth();

  return (
    <div>
      <Header authenticated={authenticated} canApprove={canApprove} signedInUser={user} />
      <Routes>
          <Route path="/" element={<Assets />}/>
          <Route exact path="/feed" element={<Feed authenticated={authenticated} />}/>
          <Route exact path="/leaderboard" element={<Leaderboard />}/>
          <Route exact path="/claim" element={<Claim authenticated={authenticated} />}/>
          <Route exact path="/approval" element={
            <ProtectedRoute user={canApprove}>
              <Approve />
            </ProtectedRoute>
            }/>
          <Route exact path="/profile/:username" element={<Profile />} />
      </Routes>
            
    </div>
  );
}

function App() {
  return (
    <Web3Provider>
      <AuthContextProvider>
        <ClaimDataProvider>
          <AppChild />
        </ClaimDataProvider>
      </AuthContextProvider>
    </Web3Provider>
  );
}

export default App;
