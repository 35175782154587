import { Avatar } from "@mui/material";
import nFormatter from "../helpers/numberFormatter";
import styles from "./LeaderItem.module.css";
import { useNavigate } from "react-router-dom";
import { cleanImageUrl } from "../helpers/cleanImageUrl";

function LeaderItem({ ranking, profile }) {
  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate(`/profile/${profile.username}`);
  };
  const src = cleanImageUrl(profile.profileImg);

  return (
    <div className={`${styles.leaderContainer} ${ranking === 1 ? styles.first : ''} ${ranking === 2 ? styles.second : ''} ${ranking === 3 ? styles.third : ''}`} onClick={handleProfileClick}>
      <span className={styles.ranking}>{ranking}</span>
      <div className={styles.user}>
        <Avatar
          sx={{ width: 72, height: 72 }}
          src={src}
          variant="rounded"
          className={styles.avatar}
        />
        <div className={styles.nameHandle}>
          <span className={styles.name}>{profile.name}</span>
          <span className={styles.twitterUser}>@{profile.username}</span>
        </div>
      </div>
      <div className={styles.memeCount}>
        <span>{nFormatter(parseInt(profile.memeCount))}</span>
        </div>

    </div>
  );
}

export default LeaderItem;
