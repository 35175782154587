import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";
import { myProfile } from "../constants/sampleData";



export const AuthContext = React.createContext({});

export const AuthContextProvider = ({children}) => {
  const [user, setUser] = useState({});
  const [canApprove, setCanApprove] = useState(false);
  const [authenticated, setAuthenticated] = useState(null);

  const location = useLocation();

  const loginUrl = `${process.env.REACT_APP_MEME_API_URL}/auth/twitter?redirect=${location.pathname}`;
  const logoutUrl = `${process.env.REACT_APP_MEME_API_URL}/auth/logout?redirect=${location.pathname}`;
  
  const useSampleData = false;
  
  const logout = () => {
    setUser({});
    setAuthenticated(null);
    setCanApprove(false);
    window.open(logoutUrl, "_self");
  };

  const login = () => {
    setAuthenticated(null);
    window.open(loginUrl, "_self");
  }

  useEffect(() => {
    async function getStatus() {
      if (useSampleData) {
        setUser(myProfile);
        setAuthenticated(true);
      } else {
      const resp = await fetch(
        `${process.env.REACT_APP_MEME_API_URL}/auth/login/success?returnPath=${location.pathname}`,
        {
          method: "GET",
          credentials: "include",
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
          },
        }
      );
      if (resp.status !== 200) {
        console.log("Failed to authenticate user");
        setAuthenticated(false);
        setUser({});
        return;
      }
      const jsonResp = await resp.json();
      setUser(jsonResp.user);
      setAuthenticated(true);
    };
  }

    if (authenticated === null) {
      getStatus()
    };

  }, [location.pathname, authenticated, useSampleData]);

  useEffect(() => {
    if (authenticated) {
      const jwt = Cookies.get('token');
      if (jwt) {
          const decoded = jwt_decode(jwt);
          if (decoded.permissions && decoded.permissions.includes('can_approve')) {
              setCanApprove(true);
              return;
          }
      }
    }
  }, [authenticated]);

  return (
    <AuthContext.Provider
      value={
        { 
          user,
          authenticated,
          canApprove,
          login,
          logout,
        }
      }
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};

export default useAuth;
