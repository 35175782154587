import React, { useState } from "react";
import Container from "@mui/material/Container";
import Modal from "@mui/material/Modal";
import { memeAssets } from "../constants/memeAssets";
import MemeAsset from "../components/MemeAsset";
import styles from './Assets.module.css';
import { Box } from "@mui/material";

function Assets() {
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    maxWidth: '1000px',
    maxHeight: '90%',
    padding: '90px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '10px',
    border: '4px solid black',
    background: '#FFF4E5',
    textAlign: 'center',
    lineHeight: '2.2rem',
  };

  return (
    <Container align="center">
      <div style={{paddingTop: "40px"}}>
        <h1>Show Us Your Dickys</h1>
        <button className={styles.howItWorks} onClick={() => setModalOpen(true)}>
          <img className={styles.howItWorksImage} src="/img/layout/sticker3x.png" alt="how it works" />
          <div className={styles.howItWorksText}><img className={styles.worksArrow} src="/img/layout/arrow3x.png" alt="decorative arrow" /><h2><q>How does it work?</q></h2></div>
        </button>
        <div className={styles.memeAsset}>
          Meme Ass-ets
        </div>
      </div>

      <div className={styles.memeGrid}>
        {memeAssets.map((asset, index) => {
          return (
            <MemeAsset src={asset} key={`Meme-asset-${index}`} />          
          );
        })}
      </div>

      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box className={styles.inner} sx={style}>
            <h1>How It Works</h1>

            <h2>STEP 1</h2>

            <p>DOWNLOAD ASSET AND MAKE MEME</p>

            <h2>STEP 2</h2>

            <p>
              POST ON TWITTER USING: <br/><span className={styles.hashtag}>#meme2earn</span> and tag <span className={styles.hashtag}>@slapcitygame</span><br />AND PASTE THE URL INTO THE FEED
            </p>

            <h2>STEP 3</h2>
            <p>GET POINTS, EARN COINS</p>

              <button className={styles.makeMemes} onClick={handleModalClose} size="large">
                Make Memes
              </button>
            <button
              className={styles.closeButton}
              onClick={handleModalClose}
            >
                X
            </button>
          </Box>
      </Modal>
    </Container>
  );
}

export default Assets;
