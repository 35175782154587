import React from 'react';
import { cleanImageUrl } from '../helpers/cleanImageUrl';
import styles from './ProfileBox.module.css';

function ProfileBox({ profile, memeCount }) {
  const url = cleanImageUrl(profile.profileImg);

  return (
    <div className={styles.profileContainer}>
      <div className={styles.photo}>
        <img src={url} alt="profile" />
      </div>
      <div className={styles.profileDetails}>
        <span className={styles.name}>{profile.name}</span>
        <span className={styles.red}>@{profile.username}</span>
      </div>
      <div className={styles.memeCount}>
        <span>{memeCount || 0}</span>
        <span className={styles.red}>Memes</span>
      </div>
    </div>
  );
}

export default ProfileBox;
