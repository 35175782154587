import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import LeaderItem from "../components/LeaderItem";
import useSWR from "swr";
import { fetcher } from "../utils/fetchers.js";
import styles from './Leaderboard.module.css';

function Leaderboard() {
  const { data, error, isLoading } = useSWR(`${process.env.REACT_APP_MEME_API_URL}/leaderboard`, fetcher)

  return (
    <Container align="center">
      <div style={{paddingTop: "40px"}}>
        <h1>Leaderboard</h1>
      </div>
      <div className={styles.boardHeader}><span className={styles.rankHeader}>#Rank</span><span className={styles.profileHeader}>Profile / Username</span><img className={styles.imageHeader} src="/img/memeAssets/d14.png" alt="lick foot" /><span className={styles.pointsHeader}>Memes</span></div>
      <div className={styles.scrollContainer}>
      {isLoading && <h2>Loading...</h2>}
      {data && data.map((leader, index) => (
        <LeaderItem
          key={`leader-${index}`}
          ranking={index + 1}
          profile={leader}
        />
      ))}
      </div>
    </Container>
  );
}

export default Leaderboard;
