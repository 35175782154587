import * as anchor from "@project-serum/anchor";
import { createContext, useContext, useEffect, useState } from "react";
import { BASE_PUBKEY, findClaimStatusKey, findDistributorKey, loadProgram, MINT_DECIMAL_MULTIPLIER } from "../utils/claim";
import { useWallet } from "@solana/wallet-adapter-react";
import claimWallets from "../claim/claimWallets.json";
import { toBytes32Array } from "../utils/claim";
import { parseBalanceMap } from "../claim/merkle/parse-balance-map";

const emptyClaimData = {
  claim: { amount: 0, index: 0, proof: null },
  claimant: { address: '', earnings: 0 },
  proof: [],
  isClaimed: false,
};

const getClaimData = async (wallet) => {
  // see if wallet in merkle JSON
  // return merkle proof, index and claim amount

  if (!wallet) {
    return {}
  }

  let claimant;

  for (let i = 0; i < claimWallets.length; i++) {
    if (claimWallets[i].address === wallet) {
      claimant = claimWallets[i];
    }
  }

  if (!claimant) {
    return {}
  }

  const { claims } = parseBalanceMap(
    claimWallets.map((claim) => ({
      address: claim.address,
      earnings: claim.earnings.toString(),
    }))
  );

  if (!claims[claimant.address]) {
    return {};
  }

  console.log(claims[claimant.address]);

  // need to convert to int32 byte array before sending in API response
  const proof = claims[claimant.address].proof.map((p) => toBytes32Array(p));

  let claim = claims[claimant.address];

  return { claimant, claim, proof };

}

const Context = createContext(
  ''
);

export const ClaimDataProvider = ({
  children,
}) => {
  const wallet = useWallet();

  const [loading, setLoading] = useState(false);
  const [claimData, setClaimData] = useState(emptyClaimData);
  const [claimAmount, setClaimAmount] = useState(0);
  const [primaryWallet, setPrimaryWallet] = useState('');

  //   const { data: session } = useSession();

  const fetchClaimData = async () => {
    setLoading(true);

    try {
      let data = await getClaimData(wallet.publicKey?.toBase58());

      if (data && data.claimant) {
        setClaimAmount(data.claimant.earnings / MINT_DECIMAL_MULTIPLIER);
      }

      if (data && data.claim) {
        // get claim status
        const [distributor, distBump] = await findDistributorKey(BASE_PUBKEY);
        const [claimStatus, bump] = await findClaimStatusKey(
          new anchor.BN(data.claim.index),
          distributor,
        );

        try {
          const program = await loadProgram(wallet);
          const status = await program.account.claimStatus.fetch(claimStatus);

          data.isClaimed = true;
        } catch (e) {
          data.isClaimed = false;
        }

        console.log(`claim data`, data);
        setClaimData(data);
      }
    } catch (err) {
      console.error("Cannot fetch profile data", err)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClaimData();
  }, [wallet]);

  return (
    <Context.Provider value={{ claimData, loading, fetchClaimData, claimAmount, primaryWallet }}>
      {children}
    </Context.Provider>
  );
};

export const useClaimData = () => useContext(Context);
