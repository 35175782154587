import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import TwitterLogin from "../TwitterLogin";
import styles from "./Header.module.css";
import useAuth from "../../hooks/useAuth";

function Header() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const { authenticated, user, canApprove } = useAuth();

  const location = useLocation();

  const toggleMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  useEffect(() => {
    setMobileNavOpen(false);
  }, [location]);

  const handleNavListClick = (event) => {
    event.stopPropagation();
  };

  return (
    <header className={styles.header}>
      <div className={styles.left}>
        <NavLink to="/">
          <img src="/img/layout/logo.png" alt="logo" className={styles.logo} />
        </NavLink>
        <a href="https://x.com/slapcitygame" target="_blank" rel="noreferrer" className={styles.twitterLink}>
          Follow Twitter
          <span className={styles.twitterLogo}><img src="/img/layout/Twitter_Social_Icon_Square_White.png" alt="twitter" /></span>
        </a>
      </div>
      <button className={styles.mobileMenuButton} onClick={toggleMobileNav}>
       menu
      </button>
      <div className={`${styles.navList} ${mobileNavOpen ? styles.mobileNavOpen : ""}`} onClick={handleNavListClick}>
        <NavLink className={({ isActive }) => (isActive ? styles.active : "")} to="/">
          Meme ass-ets
        </NavLink>
        <NavLink className={({ isActive }) => (isActive ? styles.active : "")} to="/claim" disabled>
          Claim
        </NavLink>
        <NavLink to="/feed" className={({ isActive }) => (isActive ? styles.active : "")} state={{ authenticated: authenticated }}>
          Feed
        </NavLink>
        <NavLink className={({ isActive }) => (isActive ? styles.active : "")} to="/leaderboard">
          Leaderboard
        </NavLink>
        {user && user.username && canApprove && (
          <NavLink to="/approval" className={({ isActive }) => (isActive ? "active" : "")}>
            Approve
          </NavLink>
        )}
        <TwitterLogin />
        <a href="https://x.com/slapcitygame" target="_blank" rel="noreferrer" className={styles.twitterLinkMobile}>
          Follow Twitter
          <span className={styles.twitterLogo}><img src="/img/layout/Twitter_Social_Icon_Square_White.png" alt="twitter" /></span>
        </a>
        <button className={styles.closeButton} onClick={toggleMobileNav}>X</button>
      </div>
    </header>
  );
}

export default Header;
