import styles from "./MemeAsset.module.css";

function MemeAsset({ src }) {

  return (
    <a href={src} download className={styles.container}>
      <img src={src} width="100%" alt={`Meme Asset ${src}`} />
      <div className={styles.overlay}><div className={styles.download}>Download PNG</div></div>
    </a>
  );
}

export default MemeAsset;
