import React from 'react';
import styles from './MemeImage.module.css';

function MemeImage(props) {
if (!props.memeImg) return;
return (
        <div className={styles.container}>
            <a href={props.memeUrl} target="_blank" rel="noreferrer">
                <img className={"TwitterTweetEmbed"} width="100%" loading="lazy" style={{ marginTop: '10px' }} src={props.memeImg} alt="Meme" />
            </a>
        </div>
    );
}

export default MemeImage;