import React from "react";
import styles from "./LevelIndicator.module.css";

function LevelIndicator({ memeCount = 0 }) {
  const progress = (memeCount / 50) * 100;
  return (
    <>
    <div className={styles.container}>
      <div className={styles.progressBar} style={{ width: `${progress}%`, backgroundColor: '#DE859E' }} />
    </div>
    <h2 className={styles.progressText}>{memeCount} / 50</h2>
    </>
  );
}

export default LevelIndicator;
