import React from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useAuth from "../hooks/useAuth";
import styles from "./TwitterLogin.module.css";

function TwitterLogin({ isMobile }) {
  const { user, authenticated, login, logout } = useAuth();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    if (!isMobile) {
      const connectedButton = document.querySelector(".connected-container");
      setAnchorEl(connectedButton);
    } else {
      setAnchorEl(e.currentTarget.parentNode);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const navigateToProfile = () => {
    handleClose();
    navigate(`/profile/${user.username}`);
  };

  const walletText = () => {
    if (user.wallet.length) {
      let first = user.wallet.slice(0, 4);
      let last = user.wallet.slice(-3);
      return `wallet - ${first}..${last}`;
    }
  };

  return authenticated ? (
    <div className="connected-container" id="connectedButtonContainer">
      <button
        onClick={handleClick}
        className={styles.login}
      >
          @<span className={styles.username}>{user.username}</span>
      </button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={styles.menu}
        disableAutoFocusItem={true}
      >
        <MenuItem className={styles.myItem} onClick={navigateToProfile}>Profile</MenuItem>
        <MenuItem className={styles.myItem} onClick={logout}>Disconnect Twitter</MenuItem>
        {user.wallet && user.wallet.length > 0 && (
          <MenuItem className={styles.myItem}>{walletText()}</MenuItem>
        )}
      </Menu>
    </div>
  ) : (
    <button className={styles.login} onClick={login}>
      <span>Connect Twitter</span>
    </button>
  );
}

export default TwitterLogin;
