import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import Container from "@mui/material/Container";

import { useWallet, useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import base58 from "bs58";
import Button from "@mui/material/Button";
import Cookies from "js-cookie";
import { Box, Modal } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { useClaimData } from "../providers/ClaimProvider";
import { connection, getClaimTransaction } from "../utils/claim";
import { sendAndConfirmRawTransaction } from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import ModalInner from "../components/Layout/ModalInner";

import styles from "./Claim.module.css";

const messageToSign = "Sign this to verify your wallet address for the claim.";

function Claim(props) {
  const { authenticated, login } = useAuth();
  let { username } = useParams();
  const { claimAmount, claimData, fetchClaimData } = useClaimData();

  const [claimOpen, setClaimOpen] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);

  const [items, setItems] = useState([]);
  const [profile, setProfile] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [doneLoading, setDoneLoading] = useState(false);
  const [signature, setSignature] = useState();
  const [linkedWallet, setLinkedWallet] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [submitOpen, setSubmitOpen] = useState(false);
  const handleSubmitClose = () => setSubmitOpen(false);

  const [connectOpen, setConnectOpen] = useState(false);
  const handleConnectClose = () => setConnectOpen(false);
  const location = useLocation();

  const { wallet, publicKey, connected, signMessage } = useWallet();
  const anchorWallet = useAnchorWallet();

  const clickClaim = async () => {
    if (!wallet || !connected) {
      console.log(`step 1`);
      return;
    }
    if (publicKey?.toString() !== claimData.claimant.address) {
      // enqueueSnackbar("Connect your claim wallet!", {
      //   autoHideDuration: 5000,
      //   variant: "bonkError",
      // });

      return;
    }

    if (wallet && claimData && claimData.claim) {
      console.log(anchorWallet);
      try {
        setIsClaiming(true);

        // enqueueSnackbar("Signing claim transaction!", {
        //   autoHideDuration: 5000,
        //   variant: "bonkInfo",
        // });
        const txn = await getClaimTransaction(publicKey, claimData);

        // @ts-ignore
        const signedTxn = await anchorWallet?.signTransaction(txn);

        // enqueueSnackbar("Confirming transaction!", {
        //   autoHideDuration: 5000,
        //   variant: "bonkInfo",
        // });
        const sim = await connection.simulateTransaction(signedTxn);
        console.log(sim);

        // const txSig = await sendAndConfirmRawTransaction(
        //   connection,
        //   signedTxn.serialize(),
        //   { commitment: "processed", maxRetries: 200, skipPreflight: true },
        // );
        // console.log(txSig);

        // enqueueSnackbar("Claimed!", {
        //   autoHideDuration: 5000,
        //   variant: "bonkSuccess",
        // });
        await fetchClaimData();
      } catch (err) {
        console.log(err);
        // enqueueSnackbar("Refresh page and try again.", {
        //   autoHideDuration: 1000,
        //   variant: "bonkError",
        // });
      } finally {
        setIsClaiming(false);
      }
    }
  };

  async function sign() {
    try {
      const message = new TextEncoder().encode(messageToSign);
      const uint8arraySignature = await signMessage(message);

      let jwt = Cookies.get("token");

      if (jwt) {
        const url = `${process.env.REACT_APP_MEME_API_URL}/claim/wallet`;
        const options = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify({
            signature: base58.encode(uint8arraySignature),
            wallet: anchorWallet.publicKey.toString(),
          }),
        };
        const resp = await fetch(url, options);
        const respJson = await resp.json();
        if (respJson && respJson.wallet && respJson.wallet.length > 0) {
          console.log(respJson.wallet);
          setHasError(false);

          setLinkedWallet(respJson.wallet);
          setConnectOpen(true);
        } else {
          setHasError(true);
          setConnectOpen(true);
        }
      }

      //   setSignature(base58.encode(uint8arraySignature));
    } catch (e) {
      setHasError(true);
      setConnectOpen(true);

      console.log("could not sign message");
    }
  }

  //   useEffect(() => {
  //     console.log(signature)
  //     if (signature && signature.length > 0) {
  //         verify();
  //     }
  // }, [signature]);

  useEffect(() => {
    if (connected) {
      console.log(`connected`, publicKey.toBase58());
      console.log(claimData);
    }
  }, [wallet, connected]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    maxWidth: '1000px',
    maxHeight: '90%',
    padding: '90px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '10px',
    border: '4px solid black',
    background: '#FFF4E5',
    textAlign: 'center',
    lineHeight: '2.2rem',
  };

  return (
    <div style={{ paddingTop: "40px" }}>
      <Container align="center">
        <h1>Claim Your $STACKS</h1>

        {claimOpen && (
          <>
            <div>
              Connect your wallet to claim!
              <WalletMultiButton className="defaultButton" />
            </div>

            <div>
              Claim Amount:{" "}
              <span className="text-3xl font-bold">
                {claimAmount.toFixed(0)} $STACKS
              </span>
            </div>

            <button
              onClick={clickClaim}
              className="defaultButton"
              disabled={
                publicKey?.toString() !== claimData.claimant.address ||
                claimData.isClaimed ||
                isClaiming
              }
            >
              {isClaiming && <span>Claiming...</span>}
              {!isClaiming && claimData.isClaimed && (
                <span>Claim Complete</span>
              )}
              {!isClaiming && !claimData.isClaimed && <span>Claim</span>}
            </button>
          </>
        )}

        {!claimOpen && (
          <>
            <div style={{ paddingTop: "40px" }}>
              <h2>1. Connect your Twitter</h2>
              <button
                className="defaultButton"
                disabled={authenticated}
                onClick={login}
              >
                {authenticated ? "Connected" : "Connect Twitter"}
              </button>
            </div>

            <div style={{ paddingTop: "40px" }}>
              <h2>2. Connect your wallet</h2>
              <WalletMultiButton className="defaultButton" />
            </div>

            <div style={{ paddingTop: "40px" }}>
              <h2>3. Sign message to link your wallet</h2>
              <button
                className="defaultButton"
                disabled={!publicKey || !authenticated}
                onClick={sign}
              >
                Link wallet
              </button>
            </div>

            <h2 style={{ paddingTop: "30px", fontSize: "30px" }}>
              $STACKS will be available to claim after the competition ends
            </h2>
            <img
              style={{ width: "200px", maxWidth: "90%" }}
              src="img/memeAssets/d16.png"
              alt="dicky"
            />
          </>
        )}
      </Container>

      <Modal open={connectOpen} onClose={handleConnectClose}>
        <Box className={styles.inner} sx={style}>
          {hasError ? (
            <div className={styles.walletLink}>
              <img src="/img/memeAssets/d12.png" alt="dicky" />
              <h2>Could not link your wallet!</h2>
            </div>
          ) : (
            <div className={styles.walletLink}>
              <img src="/img/memeAssets/d9.png" alt="dicky" />
              <h2>You have linked your wallet!</h2>
            </div>
          )}

          <button className={styles.closeButton} onClick={handleConnectClose}>
            X
          </button>
        </Box>
      </Modal>
    </div>
  );
}

export default Claim;
