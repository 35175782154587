import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router";
import Container from "@mui/material/Container";
import Masonry from "@mui/lab/Masonry";
import MemeImage from "../components/MemeImage";
import ProfileBox from "../components/ProfileBox";
import { fetcher } from "../utils/fetchers.js";
import useSWR from "swr";
import InfiniteScroll from "react-infinite-scroller";
import MemeBanner from "../components/MemeBanner.jsx";
import LevelIndicator from "../components/LevelIndicator.jsx";
import styles from './Profile.module.css';

const MEMES_PER_PAGE = 200;

function Profile(props) {
  let { username } = useParams();

  const [allMemes, setAllMemes] = useState([]);
  const [hasFetchedAll, setHasFetchedAll] = useState(false);
  const [memeIds, setMemeIds] = useState(new Set());
  const [page, setPage] = useState(1);

  useEffect(() => {
    setAllMemes([]);
    setMemeIds(new Set());
    setHasFetchedAll(false);
    setPage(1);
  }, [username]);

  const { data: memes, error: memesError, isValidating, isLoading } = useSWR(
    `${process.env.REACT_APP_MEME_API_URL}/profile/${username}?page=${page}&limit=${MEMES_PER_PAGE}`,
    fetcher,     
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  );

  const { data: profile, error: profileError } = useSWR(
    `${process.env.REACT_APP_MEME_API_URL}/blank/profile/${username}`,
    fetcher
  );

  useEffect(() => {
    if (memes) {
      const uniqueMemes = memes.filter((meme) => !memeIds.has(meme.id));

      if (uniqueMemes.length > 0) {
        setAllMemes((prevMemes) => [...prevMemes, ...uniqueMemes]);
        setMemeIds((prevIds) => new Set([...prevIds, ...uniqueMemes.map((meme) => meme.id)]));
      }

      if (memes.length < MEMES_PER_PAGE) {
        setHasFetchedAll(true);
      }
    }
  }, [memes, memeIds]);

  useEffect(() => {
    console.log(profile);
  }, [profile]);

  const fetchMoreData = () => {
    if (!hasFetchedAll && !isValidating && !isLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const memeCount = useMemo(() => {
    if (hasFetchedAll) return allMemes?.length;
    return "Loading...";
  }, [hasFetchedAll, allMemes]);


  const currentLevel = useMemo(() => {
    if (memeCount === "Loading...") return "";
    if (memeCount < 2) return {level: "1", name: 'Simp'};
    if (memeCount < 6) return {level: "2", name: 'Cuck'};
    if (memeCount < 20) return {level: "3", name: 'Mid'};
    if (memeCount < 50) return {level: "4", name: 'Chad'};
    if (memeCount >= 50) return {level: "5", name: 'Sigma'};;
    return '';
    }, [memeCount]);

  return (

    <div className="profile-div" style={{paddingTop: "40px"}}>
      <Container align="center">
        <h1>Profile</h1>
        <div className={styles.profile}>
          {profile && !profileError && (
            <ProfileBox profile={profile} memeCount={memeCount} />
          )}
          <div>
            <h3 className='level'>Level {currentLevel.level}</h3>
            <h2 className='levelName'>{currentLevel.name}</h2>
          </div>
        </div>
        <LevelIndicator memeCount={memeCount} />
      </Container>
      <MemeBanner />
      <Container maxWidth="xl" align="center">
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMoreData}
          hasMore={!hasFetchedAll}
          loader={<h4 key="loader">Loading...</h4>}
        >
          {!isValidating && allMemes.length < 1 && (
            <h2>No Memes Yet</h2>
          )}
          <Masonry columns={{ xs: 1, sm: 2, md: 2, lg: 3 }} spacing={3}>
            {allMemes.map((meme, index) => (
              <MemeImage
                key={`profile-${meme.id}`}
                memeImg={meme.tweetImgUrl}
                memeUrl={meme.url}
              />
            ))}
          </Masonry>
        </InfiniteScroll>
      </Container>
      {memesError && <p>Error loading memes: {memesError.message}</p>}
      {profileError && <p>Error loading profile: {profileError.message}</p>}
    </div>
  );
}

export default Profile;
