export const cleanImageUrl = (url) => {
    if (url) {
      // Remove "_bigger" and "_normal" from the URL
      let cleanedUrl = url.replace(/(_bigger|_normal)/g, '');
  
      // Check if the URL starts with "http://" and replace it with "https://"
      if (cleanedUrl.startsWith('http://')) {
        cleanedUrl = cleanedUrl.replace('http://', 'https://');
      } else if (!cleanedUrl.startsWith('https://')) {
        // If the URL doesn't start with "https://", prepend "https://"
        cleanedUrl = `https://${cleanedUrl}`;
      }
  
      return cleanedUrl;
    } else {
      return '';
    }
  };